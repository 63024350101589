<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1355">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Seguimiento de Satisfacción</h3>
            <h4 class="text-center font-weight-regular">Ingresa nuevo Seguimiento</h4>
          </div>
          <!-- Tasa de Resolución -->
          <v-simple-table class="table mt-2" fixed-header>
            <template>
              <thead>
                <tr>
                  <th class="text-center" colspan="5">Tasa de Resolución</th>
                </tr>
                <tr>
                  <th class="text-center" width="20%" scope="col">Resueltos Autoservicio</th>
                  <th class="text-center" width="20%" scope="col">Resuelto N°1</th>
                  <th class="text-center" width="20%" scope="col">Resuelto N°2</th>
                  <th class="text-center" width="20%" scope="col">Resuelto N°3</th>
                  <th class="text-center" width="20%" scope="col">No Resueltos</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in resolutionItem" :key="item.index">
                  <td class="text-center">
                    <v-text-field
                      v-model="item.resueltoAuto"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.resuelto1"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.resuelto2"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.resuelto3"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.noResuelto"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="my-6"></v-divider>
          <!-- Ingreso de Datos -->
          <v-simple-table class="table mt-2" fixed-header>
            <template>
              <thead>
                <tr>
                  <th class="text-center" rowspan="2" width="150">Mes / Año</th>
                  <th class="text-center" colspan="3">NPS</th>
                  <th class="text-center" colspan="3">MTBF</th>
                  <th class="text-center" colspan="2">Optimización</th>
                  <th class="text-center" width="140" rowspan="2"></th>
                </tr>
                <tr>
                  <th class="text-center" scope="col">Promotores</th>
                  <th class="text-center" scope="col">Neutros</th>
                  <th class="text-center separator" scope="col">Detractores</th>
                  <th class="text-center" scope="col">Toma de Decisiones</th>
                  <th class="text-center" scope="col">Cliente Externo</th>
                  <th class="text-center separator" scope="col">Cliente Interno</th>
                  <th class="text-center" scope="col">Rentabilidad por Optimización</th>
                  <th class="text-center" scope="col">Reducción de Costo</th>
                </tr>
              </thead>
              <tbody>
                <tr :v-if="items.length > 0" v-for="item in items" :key="item.index">
                  <td class="text-center">
                    <v-menu
                      ref="menu"
                      v-model="item.menu"
                      :close-on-content-click="true"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="item.date" type="month" no-title scrollable> </v-date-picker>
                    </v-menu>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.promotores"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.neutros"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center separator">
                    <v-text-field
                      v-model="item.detractores"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.decisiones"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.clienteExterno"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center separator">
                    <v-text-field
                      v-model="item.clienteInterno"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.rentOptimizacion"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="item.reduCostos"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center buttons" width="150">
                    <v-btn
                      class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                      outlined
                      color="indigo lighten-2"
                      @click="addSatisfaction(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="tway-violet--text">mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="my-6"></v-divider>
          <v-alert
            v-model="alert.saveEngagement"
            border="right"
            colored-border
            type="error"
            elevation="2"
            class="ml-2 mr-2"
          >
            {{ messageError.saveEngagement }}
          </v-alert>
          <!-- Muestra datos en BD -->
          <h4 class="text-center mt-10 font-weight-regular">Listado de Seguimiento Satisfacción</h4>
          <v-alert
            v-model="alert.updateEngagement"
            border="right"
            colored-border
            type="error"
            elevation="2"
            class="ml-2 mr-2"
          >
            {{ messageError.updateEngagement }}
          </v-alert>
          <v-simple-table class="table mt-2" fixed-header>
            <template>
              <thead>
                <tr>
                  <th class="text-center" rowspan="2" width="150">Mes / Año</th>
                  <th class="text-center" width="31%" colspan="3">NPS</th>
                  <th class="text-center" width="28%" colspan="3">MTBF</th>
                  <th class="text-center" colspan="2">Optimización</th>
                  <th class="text-center" rowspan="2" width="157"></th>
                </tr>
                <tr>
                  <th class="text-center" scope="col">Promotores</th>
                  <th class="text-center" scope="col">Neutros</th>
                  <th class="text-center separator" scope="col">Detractores</th>
                  <th class="text-center" scope="col">Toma de Decisiones</th>
                  <th class="text-center" scope="col">Cliente Externo</th>
                  <th class="text-center separator" scope="col">Cliente Interno</th>
                  <th class="text-center" width="150" scope="col">Rentabilidad por Optimización</th>
                  <th class="text-center" width="150" scope="col">Reducción de Costo</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :v-if="satisfactions.length > 0"
                  v-for="entry in satisfactions"
                  :key="entry"
                  :class="entry.isVisible ? hidden : activeClass"
                  class="edit_row"
                >
                  <td class="text-center">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="true"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="entry.date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="entry.date" type="month" no-title scrollable> </v-date-picker>
                    </v-menu>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="entry.promotores"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="entry.neutros"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center separator">
                    <v-text-field
                      v-model="entry.detractores"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="entry.takeDecitions"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="entry.externalClient"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center separator">
                    <v-text-field
                      v-model="entry.internalClient"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="entry.costEffectiveness"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model="entry.reduction"
                      maxlength="15"
                      minlenght="0"
                      :counter="15"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="text-center buttons">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          class="save"
                          @click="saveSatisfaction(entry)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>save</v-icon>
                        </v-btn>
                      </template>
                      <span>Guardar</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteSatisfaction(entry.index)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row>
            <v-col md="12" class="justify-center">
              <div class="btn d-flex justify-center mt-6">
                <v-btn
                  class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text justify-center"
                  outlined
                  color="indigo lighten-2"
                  @click="saveAll(resolutionItem[0])"
                >
                  <v-icon class="tway-violet--text">mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KpiBusinessService from "@/services/pmo/kpi/kpiBusinessService";

export default {
  data: () => ({
    dialog: false,
    resolutionItem: [
      {
        index: 1,
        resueltoAuto: "",
        resuelto1: "",
        resuelto2: "",
        resuelto3: "",
        noResuelto: "",
      },
    ],
    items: [
      {
        neutros: 0,
        detractores: 0,
        decisiones: 0,
        promotores: 0,
        clienteInterno: 0,
        clienteExterno: 0,
        rentOptimizacion: 0,
        reduCostos: 0,
        index: 30,
        date: new Date().toISOString().substr(0, 7),
        menu: false,
      },
    ],
    satisfactions: [],
    /* maxIndex: 0, */
    nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 15 || "Máximo 15 carácteres."],
    menu: false,
    menu2: false,
    isVisible: false,
    activeClass: "is-visible",
    isShow: true,
    alert: {
      saveEngagement: false,
      updateEngagement: false,
    },
    messageError: {
      saveEngagement: false,
      updateEngagement: false,
    },
  }),
  methods: {
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadSatisfactionCharts");
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    addSatisfaction() {
      const data = {
        date: this.items[0].date,
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        promotores: this.items[0].promotores,
        neutros: this.items[0].neutros,
        detractores: this.items[0].detractores,
        takeDecitions: this.items[0].decisiones,
        externalClient: this.items[0].clienteExterno,
        internalClient: this.items[0].clienteInterno,
        costEffectiveness: this.items[0].rentOptimizacion,
        reduction: this.items[0].reduCostos,
        index: this.maxIndex + 1,
      };

      KpiBusinessService.addSatisfactionTrace(data)
        .then(() => {
          this.item = [];
          this.getSatisfaction();
          this.getMaxIndex();
          this.clearForm();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveEngagement = true;
          this.messageError.saveEngagement = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveEngagement = false), 4000);
        });
    },
    saveSatisfaction(item) {
      const data = {
        date: item.date,
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        promotores: item.promotores,
        neutros: item.neutros,
        detractores: item.detractores,
        takeDecitions: item.takeDecitions,
        externalClient: item.externalClient,
        internalClient: item.internalClient,
        costEffectiveness: item.costEffectiveness,
        reduction: item.reduction,
        index: item.index,
      };

      KpiBusinessService.addSatisfactionTrace(data)
        .then(() => {
          this.getSatisfaction();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.updateEngagement = true;
          this.messageError.updateEngagement = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.updateEngagement = false), 4000);
        });
    },
    clearForm() {
      this.items[0].date = new Date().toISOString().substr(0, 7);
      this.items[0].promotores = 0;
      this.items[0].neutros = 0;
      this.items[0].detractores = 0;
      this.items[0].decisiones = 0;
      this.items[0].clienteExterno = 0;
      this.items[0].clienteInterno = 0;
      this.items[0].rentOptimizacion = 0;
      this.items[0].reduCostos = 0;
    },
    getMaxIndex() {
      KpiBusinessService.getIndexMaxSatisfaction(this.projectIds)
        .then((data) => {
          this.maxIndex = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSatisfaction() {
      KpiBusinessService.getSatisfaction(this.projectIds)
        .then((data) => {
          this.satisfactions = data;
        })
        .catch((err) => console.dir(err));
    },
    deleteSatisfaction(index) {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        index: index,
      };

      KpiBusinessService.deleteSatisfaction(data)
        .then((data) => {
          console.log(data);
          this.getSatisfaction();
        })
        .catch((err) => console.dir(err));
    },
    getResolutionRate() {
      KpiBusinessService.getResolutionRate(this.projectIds)
        .then((data) => {
          this.resolutionItem[0].resueltoAuto = data[0].selfService;
          this.resolutionItem[0].resuelto1 = data[0].resolution1;
          this.resolutionItem[0].resuelto2 = data[0].resolution2;
          this.resolutionItem[0].resuelto3 = data[0].resolution3;
          this.resolutionItem[0].noResuelto = data[0].unsolved;
          console.log("datos resolution: ", data);
        })
        .catch((err) => console.dir(err));
    },
    saveResolution(item) {
      const request = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        selfService: item.resueltoAuto,
        resolution1: item.resuelto1,
        resolution2: item.resuelto2,
        resolution3: item.resuelto3,
        unsolved: item.noResuelto,
      };
      console.log("data request: ", request);
      KpiBusinessService.saveResolutionRate(request)
        .then(() => {
          this.getResolutionRate();
        })
        .catch((err) => console.dir(err));
    },
    saveAll(item) {
      this.saveResolution(item);
      this.getResolutionRate();
      this.closeConfigurationDialog();
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.projectIds = pmoProjectIds;

    this.getMaxIndex();
    this.getSatisfaction();
    this.getResolutionRate();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.modalSystem .edit_row .v-input__prepend-outer {
  display: none;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
.separator {
  border-right: 2px solid rgb(225, 223, 223);
}
</style>
