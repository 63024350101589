<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadSatisfactionCharts="reloadCharts"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Indicador Satisfacción</h3>

    <div class="resumen">
      <div class="resumen-detail">
        <div class="tway-violet--text mb-4">
          <span style="font-size: 18px">Resumen</span>
        </div>
        <div class="resumen-detail-item" v-for="(itemResumen, i) in summaryKPINegocio.resumen" :key="i">
          <div class="resumen-detail-item-data">
            {{ itemResumen.description }}
          </div>
          <div class="resumen-detail-item-data">{{ itemResumen.value }}</div>
        </div>
      </div>
      <div class="resumen-bar padding-fix">
        <VueApexCharts
          :series="summaryKPINegocio.series"
          :options="summaryKPINegocio.options"
          height="150"
          type="bar"
          class="full"
        />
      </div>
    </div>
    <div class="graphics-container-triple">
      <div>
        <!-- NPS -->
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              NPS Histórico
              <ToolTips
                width="300px"
                description="Net Promotor Score es un indicador de Satisfacción"
                formula="(%Promotores - %Detractores)"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="historyKPINegocio"
              :series="historyKPINegocio.series"
              :options="historyKPINegocio.options"
              title=""
              height="310"
            />
          </div>
        </div>
        <!-- Rratio Historico -->
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Ratio Histórico
              <ToolTips
                width="300px"
                description="Promotores: evaluaron con 9 ó 10."
                description2="Neutros: evaluaron con 7 u 8."
                description3="Detractores: evaluaron con 1 a 6."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="ratioHistoryKPINegocio"
              v-if="ratioHistoryKPINegocio.series.length > 0"
              type="bar"
              height="300"
              :series="ratioHistoryKPINegocio.series"
              :options="ratioHistoryKPINegocio.options"
            />
          </div>
        </div>
        <!-- Composicion historica -->
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Composición Histórica
              <ToolTips
                width="310px"
                description="Composición de la cantidad de promotores, neutros y detractores que respondieron la encuesta de satisfacción realizada en cada periodo."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="compositionKPINegocio"
              v-if="compositionKPINegocio.series.length > 0"
              type="bar"
              height="300"
              :series="compositionKPINegocio.series"
              :options="compositionKPINegocio.options"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="graphics-container-triple padding-fix">
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              MTBF Toma de Decisiones
              <ToolTips
                width="370"
                description="MTBF es el tiempo promedio entre averías o fallas de un proceso que impacta en nuestra toma decisiones."
                formula="(Tiempo disponible - Tiempo inactivo) / #fallas"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="takeDecisions"
              :series="takeDecisions.series"
              :options="takeDecisions.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              MTBF Cliente Externo
              <ToolTips
                width="380"
                description="MTBF es el tiempo promedio entre averías o fallas de un proceso que impacta en la satisfacción de nuestros clientes."
                formula="(Tiempo disponible - Tiempo inactivo) / #fallas"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="externalCustomer"
              :series="externalCustomer.series"
              :options="externalCustomer.options"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              MTBF Cliente Interno
              <ToolTips
                width="380"
                description="MTBF es el tiempo promedio entre averías o fallas de un proceso que impacta el trabajo de nuestros colaboradores."
                formula="(Tiempo disponible - Tiempo inactivo) / #fallas"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="internalCustomer"
              :series="internalCustomer.series"
              :options="internalCustomer.options"
              height="300"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="list-top-10 graphics-container">
      <div v-if="false">
        <div class="mx-4 mt-1 pt-2 tway-violet--text">
          <p>
            Rentabilidad por Optimización
            <ToolTips
              width="310px"
              description="Rentabilidad por optimizaciones al integrar herramientas
                tecnológicas digitales."
            />
          </p>
        </div>
        <div>
          <VueApexCharts
            ref="costEffectiveness"
            :series="costEffectiveness.series"
            :options="costEffectiveness.options"
            height="400"
            type="area"
          />
        </div>
      </div>
      <div v-if="false">
        <div class="mx-4 mt-1 pt-2 tway-violet--text">
          <p>
            Reducción de Costo
            <ToolTips
              width="310px"
              description="Reducción costos en ejecución de procesos con herramientas
                tecnológicas digitales."
            />
          </p>
        </div>
        <div>
          <VueApexCharts
            ref="costReduction"
            :series="costReduction.series"
            :options="costReduction.options"
            height="400"
            type="area"
          />
        </div>
      </div>
      <div>
        <div class="mx-4 mt-1 pt-2 tway-violet--text">
          <p class="text-center">
            Tasa de Resolución
            <ToolTips
              width="380"
              description="Autoservicio: cualquier plataforma de auto atención, que le permite al usuario resolverse sus incidencias."
              description2="N1: servicio de primer contacto con el cliente."
              description3="N2: primer nivel de escalamiento para la resolución de incidencias."
              description4="N3: segundo nivel de escalamiento hacia especialistas para resolución de incidencias."
              formula="(Cantidad de clientes de un canal digital) / (Cantidad de clientes que realizaron la acción esperada)"
            />
          </p>
        </div>
        <div class="pt-10">
          <VueApexCharts
            v-if="firstLine.series.length > 0"
            :series="firstLine.series"
            :options="firstLine.options"
            height="400"
            type="donut"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/kpi/seguimiento.vue";
import KpiBusinessService from "@/services/pmo/kpi/kpiBusinessService";
//import SimpleBubbleChart from "../../components/charts/pmo/apex/bubble/SimpleBubbleChart";
//import PolarArea from "../../components/charts/pmo/apex/polar-area/PolarArea";
// getSummaryKPINegocio
// getHistoryKPINegocio
// getRatioHistoryKPINegocio
// getCompositionKPINegocio
// import StackedBars100 from "../../components/charts/pmo/apex/bar/StackedBars100";
//import SimpleLine from "../../components/charts/pmo/apex/line/SimpleLine";
//import StackedColumns100 from "../../components/charts/pmo/apex/column/StackedColumns100";
//import StackedBars from "../../components/charts/pmo/apex/bar/StackedBars";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "KPIBusiness",
  components: {
    Seguimiento,
    //SimpleBubbleChart,
    //PolarArea,
    // StackedBars100,
    //SimpleLine,
    //StackedColumns100,
    //StackedBars,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      charts: {
        timeLine: {
          serie: null,
        },
        stacked: {
          serie: [],
        },
      },
      summaryKPINegocio: {
        resumen: [],
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 150,
            stacked: true,
            toolbar: {
              show: false,
            },
            //stackType: '100%'
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            show: true,
            curve: "smooth",
            lineCap: "round",
            width: 2,
            dashArray: 0,
          },
          title: {
            text: "",
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: {
                style: { colors: "#7319D5" },
                formatter: function (val) {
                  return val;
                },
              },
              title: {
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
            x: {
              formatter: function () {
                return "Mes actual";
              },
            },
          },
          legend: {
            position: "left",
            horizontalAlign: "left",
            offsetX: 0,
            offsetY: 35,
          },
          colors: ["#008F39", "#E5Be01", "#D7263D"],
        },
      },
      fechaActual: "",
      historyKPINegocio: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            width: 800,
            type: "line",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ["#7319D5"],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
          },
          stroke: {
            show: true,
            curve: "smooth",
            lineCap: "round",
            width: 2,
            dashArray: 0,
          },
          title: {
            text: "",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "NPS",
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
            },
          ],
        },
      },
      ratioHistoryKPINegocio: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 350,
            stacked: true,
            stackType: "100%",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 5,
            },
          },
          responsive: [
            {
              breakpoint: 680,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          colors: ["#16C62E", "#AEEA00", "#D7263D"],
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            offsetX: 0,
            offsetY: 0,
          },
          dataLabels: {
            style: {
              colors: ["#232423", "#6f6e6e", "white"],
            },
            formatter: function (val) {
              return Math.round(val);
            },
          },
        },
      },
      compositionKPINegocio: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ["#4A148C", "#7319D5", "#D7263D"],
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 5,
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: [],
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            offsetX: 20,
            offsetY: 0,
          },
        },
      },
      takeDecisions: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            stacked: false,
            marginBottom: 0,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 2, 5, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: { columnWidth: "65%" },
          },
          markers: { size: 3 },
          xaxis: {
            categories: [],
            labels: {
              show: true,
              rotate: -60,
            },
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              opposite: false,
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "Horas",
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              /* formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " horas";
              }
              return y;

            } */
            },
          },
        },
      },
      externalCustomer: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            marginBottom: 0,
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 2, 5, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: { columnWidth: "65%" },
          },
          markers: { size: 3 },
          xaxis: {
            categories: [],
            labels: {
              show: true,
              rotate: -60,
            },
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              seriesName: "MTBF",
              opposite: false,
              axisTicks: { show: true },
              axisBorder: { show: true, color: "#16C62E" },
              title: { text: "Horas", style: { color: "#16C62E" } },
              labels: {
                style: { color: "#16C62E" },
              },
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              /* formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " horas";
              }
              return y;

            } */
            },
          },
        },
      },
      internalCustomer: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "line",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          grid: {
            height: 275,
            padding: {
              bottom: 0,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 2, 5, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: { columnWidth: "65%" },
          },
          markers: { size: 3 },
          xaxis: {
            categories: [],
            labels: {
              show: true,
              rotate: -60,
            },
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              seriesName: "MTBF",
              opposite: false,
              axisTicks: { show: true },
              axisBorder: { show: true, color: "#7F7F7F" },
              title: { text: "Horas", style: { color: "#7F7F7F" } },
              labels: {
                style: { color: "#7F7F7F" },
              },
            },
          ],
          // tooltip: {
          //   shared: true,
          //   intersect: false,
          //   y: {
          //     /* formatter: function (y) {
          //     if (typeof y !== "undefined") {
          //       return y.toFixed(0) + " horas";
          //     }
          //     return y;

          //   } */
          //   },
          // },
        },
      },
      costEffectiveness: {
        series: [],
        options: {
          series: [],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            stacked: false,
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "%",
                style: { color: "#7319D5" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            x: {
              show: false,
            },
            y: {
              show: false,
              /*            formatter: function(value) {
            return '% ' + value
            } */
            },
          },
        },
      },
      costReduction: {
        series: [],
        options: {
          series: [],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeate
            },
          },
          xaxis: {
            categories: [],
            color: "#16C62E",
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "%",
                style: { color: "#16C62E" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            x: {
              show: false,
            },
            y: {
              show: false,
              formatter: function (value) {
                return value + " %";
              },
            },
          },
        },
      },
      firstLine: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          stroke: {
            show: true,
            curve: "smooth",
            lineCap: "round",
            width: 2,
            dashArray: 0,
          },
          colors: ["#AEEA00", "#7F7F7F", "#7319D5", "#16C62E", "#4A148C"],
          labels: [],
          legend: {
            position: "bottom",
            horizontalAlign: "left",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          dataLabels: {
            style: {
              colors: ["#232423", "white", "white", "#232423", "white"],
            },
            dropShadow: {
              enabled: false,
            },
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
        },
      },
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await KpiBusinessService.getSummaryKPINegocio(this.project)
        .then((data) => {
          this.fechaActual = data.fechaActual[0].dates;
          this.summaryKPINegocio = data;
        })
        .catch((err) => console.dir(err));

      await KpiBusinessService.getHistoryKPINegocio(this.project)
        .then((data) => {
          console.log("nps data: ", data);
          this.historyKPINegocio = data;
          this.$refs.historyKPINegocio.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));

      await KpiBusinessService.getRatioHistoryKPINegocio(this.project)
        .then((data) => {
          this.ratioHistoryKPINegocio.options.xaxis.categories = data.fechas[0].dates;
          this.ratioHistoryKPINegocio.series = data.series;
        })
        .catch((err) => console.dir(err));

      await KpiBusinessService.getCompositionKPINegocio(this.project)
        .then((data) => {
          this.compositionKPINegocio.options.xaxis.categories = data.fechas[0].dates;
          this.compositionKPINegocio.series = data.series;
        })
        .catch((err) => console.dir(err));

      await KpiBusinessService.getCostEffectiveness(this.project)
        .then((data) => {
          this.$refs.costEffectiveness.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
          this.costEffectiveness.series = data.series;
        })
        .catch((err) => console.dir(err));
      await KpiBusinessService.getCostReduction(this.project)
        .then((data) => {
          this.$refs.costReduction.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
          this.costReduction.series = data.series;
        })
        .catch((err) => console.dir(err));
      await KpiBusinessService.getExternalCustomer(this.project)
        .then((data) => {
          this.$refs.externalCustomer.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
          this.externalCustomer.series = data.series;
        })
        .catch((err) => console.dir(err));
      await KpiBusinessService.getInternalCustomer(this.project)
        .then((data) => {
          this.$refs.internalCustomer.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
          this.internalCustomer.series = data.series;
        })
        .catch((err) => console.dir(err));
      await KpiBusinessService.getTakeDecisions(this.project)
        .then((data) => {
          this.$refs.takeDecisions.updateOptions({
            xaxis: {
              categories: data.series[0].dates,
            },
          });
          this.takeDecisions.series = data.series;
        })
        .catch((err) => console.dir(err));
      await KpiBusinessService.getFirstLine(this.project)
        .then((data) => {
          this.firstLine.series = data.series[0].data;
          this.firstLine.options.labels = data.labels;
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 235px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  padding-bottom: 10px;
}

.resumen > .resumen-detail {
  width: 17%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  height: fit-content;
}
.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.resumen > .resumen-detail > .resumen-detail-item > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}
.resumen > .resumen-detail > .resumen-detail-item > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 80%;
  margin-top: 55px;
  height: fit-content;
}
.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}
.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px;
  height: 500px;
}
.seguimiento-costo {
  width: 45%;
  margin: 2px;
}
.seguimiento-periodo {
  width: 45%;
  margin: 2px;
}
.list-top-10 {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin: 25px 0px 25px 0px;
  div {
    width: 33%;
    div {
      width: 100%;
    }
  }
}

.risk_classification {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
.risk {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.concurrence {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.impact {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.strategy {
  width: 10%;
  margin: 0px 5px 0px 5px;
}
.contingency {
  width: 20%;
  margin: 0px 5px 0px 5px;
}
.responsible {
  width: 20%;
  margin: 0px 5px 0px 5px;
}
.full {
  width: 100%;
  height: fit-content;
}
.TercioFull {
  width: 50%;
}
.graphics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 20px 10px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: column;
  }
}
.graphics-container-duo,
.graphics-container-triple,
.graphics-container-cuatriple {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px 20px 3px 20px;
  margin: 25px 0px 25px 0px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  div {
    display: flex;
    flex-direction: row;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 45%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}

.graphics-container-triple {
  div {
    div {
      width: 32%;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.graphics-container-cuatriple {
  div {
    div {
      width: 25%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.padding-fix {
  padding-bottom: 0px;
}
</style>
